@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}
@import 'react-datepicker/dist/react-datepicker.css';
@import '@aws-amplify/ui-react/styles.css';
.amplify-button--primary {
  background-color: #00b6cb;
}
